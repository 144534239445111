// 全局获取导出枚举字段接口
import _axios, { post, get, put } from '@/lin/plugin/axios'

class EnmuApi {
  // 异步获取枚举列表
  async getEnmuList() {
    // 发送post请求，获取报告字典
    const res = await post('/reportDownloadCenter/reportDict/queryReportDict')
    // 返回结果
    return res
  }
  //新区域
  async getNewArea() {
    const res = await post('/adminChain/staff/organization/my-managed-region')
    return res
  }
  //新门店
  async getNewShop(data) {
    const res = await post('/adminChain/staff/organization/my-managed-store', data)
    return res
  }
}

export default new EnmuApi()
