<template>
  <div class="combo-manager">
    <!-- 筛选区域 -->
    <div class="filter-section">
      <div class="left-section">
        <div class="store-select">
          <span class="label">门店：</span>
          <el-select
            v-model="storeId"
            placeholder="请选择门店"
            filterable
            class="store-select-input"
            @change="handleStoreChange"
          >
            <el-option v-for="item in storeOptions" :key="item.code" :label="item.name" :value="item.code" />
          </el-select>
        </div>
        <div class="scene-filter">
          <span class="label" style="width: 120px;">套餐场景：</span>
          <el-radio-group v-model="sceneType">
            <el-radio-button label="套免" class="active-radio" @click="handleSceneChange">套免</el-radio-button>
            <el-radio-button label="套餐" disabled>套餐</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <div class="right-section">
        <el-button type="primary" @click="handleBatchShelf">上架套免</el-button>
      </div>
    </div>

    <!-- 表格区域 -->
    <el-table :data="tableData" style="width: 100%" v-loading="loading">
      <el-table-column type="index" label="序号" width="60" align="center" />
      <el-table-column prop="comboCategory" label="套餐类型" show-overflow-tooltip width="130" align="center">
        <template #default="scope">
          {{ '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="comboName" label="套餐场景" show-overflow-tooltip width="130" align="center">
        <template #default="scope">
          {{ scope.row.isConsumeFree === 1 ? '套免' : '套餐' }}
        </template>
      </el-table-column>
      <el-table-column label="套餐图片" width="130" align="center">
        <template #default="scope">
          <el-image style="width: 46px; height: 46px;line-height: 46px;" :src="scope.row.pic" fit="cover" />
        </template>
      </el-table-column>
      <el-table-column prop="comboName" label="套餐名称" show-overflow-tooltip width="130" align="center" />
      <!-- <el-table-column prop="minimum" label="套餐价格" width="130" align="center" /> -->
      <el-table-column prop="spec" label="套餐规格" width="130" align="center" />
      <el-table-column prop="content" label="套餐内容" width="300">
        <template #default="{row}">
          <div class="detail-item" v-if="row.comboContent.requiredContents.length > 0">
            <span class="label" style="font-weight: 600;">必选商品：</span>
            <div v-for="item in row.comboContent.requiredContents" :key="item.id">
              <div class="content" v-if="item.name">
                <span v-if="item.name">{{ item.name }}</span>
                <span v-if="item.spec">({{ item.spec }})</span>
                <span v-if="item.count">*{{ item.count }}</span>
                <span v-if="item.unit">{{ item.unit }}</span>
              </div>
            </div>
          </div>
          <div v-if="row.comboContent.optionContents.length > 0">
            <span class="label" style="font-weight: 600;">可选方案：</span>
            <div v-for="item in row.comboContent.optionContents" :key="item.id">
              <div style="font-weight: 500;" v-if="item.name">{{ item.name }}:</div>
              <div class="content" v-for="(ii, i) in item.products" :key="i">
                <span v-if="ii.name">{{ ii.name }}</span>
                <span v-if="ii.spec">({{ ii.spec }})</span>
                <span v-if="ii.count">*{{ ii.count }}</span>
                <span v-if="ii.unit">{{ ii.unit }}</span>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="unit" label="最小单位" width="130" align="center" />
      <el-table-column prop="miniNumber" label="起售数量" width="130" align="center" />
      <el-table-column prop="retailPrice" label="套餐售价" width="130" align="center" />
      <el-table-column prop="channel" label="支持投放渠道" width="130" align="center">
        <template #default="scope">{{ '--' }}</template>
      </el-table-column>
      <el-table-column prop="isShelves" label="套餐状态" width="130" align="center">
        <template #default="scope">
          <el-tag :type="scope.row.isShelves === 1 ? 'success' : 'info'">
            {{ scope.row.isShelves === 1 ? '上架' : '下架' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="upShelvesTime" label="上架时间" width="150" align="center" />
      <el-table-column label="操作" width="130" fixed="right" align="center">
        <template #default="scope">
          <el-button v-if="scope.row.isShelves === 1" type="primary" size="small" @click="handleShelf(scope.row, 0)">
            下架
          </el-button>
          <el-button v-else type="info" size="small" @click="handleShelf(scope.row, 1)">
            上架
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 主页面分页 -->
    <div class="pagination-container">
      <el-pagination
        v-model:current-page="mainCurrentPage"
        v-model:page-size="mainPageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="mainTotal"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleMainSizeChange"
        @current-change="handleMainCurrentChange"
      />
    </div>

    <!-- 添加弹框组件 -->
    <el-dialog
      v-model="dialogVisible"
      title="套餐搜索"
      width="80%"
      style="height: 500px;"
      :destroy-on-close="true"
      @close="handleClose"
    >
      <div class="dialog-content">
        <!-- 搜索区域 -->
        <div class="search-section">
          <div class="search-item">
            <span class="label">套餐名称：</span>
            <el-input
              v-model="searchForm.comboName"
              placeholder="请输入套餐名称关键字"
              class="search-input"
              clearable
              @keyup.enter="handleSearch"
              @clear="handleSearch"
            />
          </div>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </div>

        <!-- 表格区域 -->
        <el-table :data="searchTableData" style="width: 100%" v-loading="searchLoading" border>
          <el-table-column type="index" label="序号" width="60" align="center" />
          <el-table-column prop="scene" label="套餐场景" width="130" align="center">
            <template #default="scope">
              {{ scope.row.isConsumeFree === 1 ? '套免' : '套餐' }}
            </template>
          </el-table-column>
          <el-table-column label="套餐图片" width="130" align="center">
            <template #default="scope">
              <el-image style="width: 40px; height: 40px" :src="scope.row.pic" fit="cover" />
            </template>
          </el-table-column>
          <el-table-column prop="comboName" label="套餐名称" min-width="130" align="center" show-overflow-tooltip />
          <el-table-column prop="spec" label="套餐规格" min-width="130" align="center" />
          <el-table-column prop="content" label="套餐内容" min-width="300">
            <template #default="{row}">
              <div class="detail-item" v-if="row.comboContent.requiredContents.length > 0">
                <span class="label" style="font-weight: 600;">必选商品：</span>
                <div v-for="item in row.comboContent.requiredContents" :key="item.id">
                  <div class="content" v-if="item.name">
                    <span v-if="item.name">{{ item.name }}</span>
                    <span v-if="item.spec">({{ item.spec }})</span>
                    <span v-if="item.count">*{{ item.count }}</span>
                    <span v-if="item.unit">{{ item.unit }}</span>
                  </div>
                </div>
              </div>
              <div v-if="row.comboContent.optionContents.length > 0">
                <span class="label" style="font-weight: 600;">可选方案：</span>
                <div v-for="item in row.comboContent.optionContents" :key="item.id">
                  <div style="font-weight: 500;" v-if="item.name">{{ item.name }}:</div>
                  <div class="content" v-for="(ii, i) in item.products" :key="i">
                    <span v-if="ii.name">{{ ii.name }}</span>
                    <span v-if="ii.spec">({{ ii.spec }})</span>
                    <span v-if="ii.count">*{{ ii.count }}</span>
                    <span v-if="ii.unit">{{ ii.unit }}</span>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="unit" label="最小单位" width="130" align="center" />
          <el-table-column prop="miniNumber" label="起售数量" width="130" align="center" />
          <el-table-column prop="guidePrice" label="销售指导价" width="130" align="center" />
          <el-table-column prop="channel" label="支持投放渠道" width="130" align="center">
            <template #default="scope">{{ '--' }}</template>
          </el-table-column>
          <el-table-column label="操作" width="130" fixed="right" align="center">
            <template #default="scope">
              <el-button type="primary" link @click="handleSelect(scope.row)">
                上架
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div class="pagination-container">
          <el-pagination
            v-model:current-page="currentPage"
            v-model:page-size="pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </el-dialog>
    <el-dialog v-model="dialogPriceVisible" title="确定上架" width="30%" @close="handleCancel">
      <el-form :model="formData" ref="formRef" :rules="rules" label-width="auto">
        <el-form-item label="设置套餐售价：" prop="retailPrice" class="is-required">
          <el-input v-model.trim="formData.retailPrice" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
          <el-button @click="handleCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive } from 'vue'
import { ElMessage, ElDialog, ElPagination, ElMessageBox } from 'element-plus'
import API from '@/plugin/storeTransactionManager/service/api'
import commonApi from '@/api'
// 门店选择数据
const storeId = ref('')
let storeOptions = ref([])
// 套餐场景
const sceneType = ref('套免')

// 表格数据
const loading = ref(false)
const tableData = ref([])

// 弹框相关数据
const dialogVisible = ref(false)
const searchForm = ref({
  comboName: '',
})
const searchLoading = ref(false)
const searchTableData = ref([])

// 分页相关
const currentPage = ref(1)
const pageSize = ref(10)
const total = ref(100)

// 主页面分页相关
const mainCurrentPage = ref(1)
const mainPageSize = ref(10)
const mainTotal = ref(0)

const dialogPriceVisible = ref(false)
let formData = ref({
  retailPrice: '',
})

const rules = {
  retailPrice: [
    { required: true, message: '请输入套餐售价', trigger: ['blur', 'change'] },
    {
      validator: (rule, value, callback) => {
        if (value < 0) {
          callback(new Error('套餐售价只能输入整数'))
        } else if (value.includes('.')) {
          callback(new Error('套餐售价只能输入整数'))
        } else if (!/^\d+$/.test(value)) {
          callback(new Error('套餐售价只能输入整数'))
        } else {
          callback()
        }
      },
    },
  ],
}
// 处理上下架
const handleShelf = (row, isShelf) => {
  ElMessageBox.confirm('下架套免套餐，价目表中售卖的该套免价目会自动移除，确定要继续下架？', '确定下架', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(async () => {
      loading.value = true
      const params = {
        isShelves: isShelf,
        storeProductCode: row.comboCode,
      }
      const res = await API.ShelvesChange(params)
      if (Number(res.code) == 200) {
        loading.value = false
        ElMessage.success(`${isShelf === 1 ? '上架' : '下架'}成功`)
        await getComboList(params)
      }
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '取消下架',
      })
    })
}
const handleStoreChange = val => {
  console.log(val, '=====')
  storeId.value = val
  getComboList()
}
// 搜索处理
const handleSearch = () => {
  getComboPopupList()
}

// 分页处理
const handleSizeChange = val => {
  pageSize.value = val
  handleSearch()
}

const handleCurrentChange = val => {
  currentPage.value = val
  handleSearch()
}
let currentRow = ref({})
// 选择处理
const handleSelect = row => {
  currentRow.value = row
  dialogPriceVisible.value = true
}
const handleCancel = () => {
  dialogPriceVisible.value = false
  formRef.value.resetFields()
  formData.value.price = ''
}
const formRef = ref()
const handleSubmit = row => {
  formRef.value.validate().then(async valid => {
    if (valid) {
      const params = {
        isShelves: 1,
        retailPrice: Number(formData.value.retailPrice),
        storeProductCode: currentRow.value.comboCode,
      }
      const res = await API.ShelvesChange(params)
      if (Number(res.code) == 200) {
        dialogPriceVisible.value = false
        ElMessage.success('上架成功')
        getComboPopupList()
      } else {
        ElMessage.error(res.msg || '上架失败')
      }
    }
  })
}
// 修改批量上架函数，打开弹框
const handleBatchShelf = () => {
  dialogVisible.value = true
  getComboPopupList()
}

// 主页面分页处理
const handleMainSizeChange = val => {
  mainPageSize.value = val
  getComboList()
}

const handleMainCurrentChange = val => {
  mainCurrentPage.value = val
  getComboList()
}

// 修改获取列表数据的方法
const getComboList = async () => {
  const params = {
    pageNum: mainCurrentPage.value,
    pageSize: mainPageSize.value,
    storeCode: storeId.value,
    isShelves: 1,
    isConsumeFree: 1,
  }
  const res = await API.getComboPage(params)
  if (Number(res.code) == 200) {
    tableData.value = res.data.records || []
    mainTotal.value = res.data.totalRecords || 0
  }
}

const getComboPopupList = async () => {
  const params = {
    pageNum: currentPage.value,
    pageSize: pageSize.value,
    storeCode: storeId.value,
    isShelves: 0,
    isConsumeFree: 1,
    comboName: searchForm.value.comboName,
  }
  const res = await API.getComboPage(params)
  if (Number(res.code) == 200) {
    searchTableData.value = res.data.records || []
    total.value = res.data.totalRecords
  } else {
    ElMessage.error(res.msg || '查询失败')
  }
}

const handleClose = () => {
  currentPage.value = 1
  pageSize.value = 10
  searchForm.value.comboName = ''
  getComboList()
}

onMounted(async () => {
  await getstoreList()
  await getComboList()
})

const getstoreList = async () => {
  const res = await commonApi.getNewShop()
  storeOptions.value = res.data
    .map(item => ({
      ...item,
      name: item.code + '--' + item.name,
    }))
    .sort((a, b) => Number(a.code.slice(1)) - Number(b.code.slice(1)))
  storeId.value = storeOptions.value[0].code
}
</script>

<style lang="scss" scoped>
.combo-manager {
  padding: 20px;

  .filter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 16px 20px;

    .left-section {
      display: flex;
      align-items: center;
      gap: 20px;

      .store-select {
        display: flex;
        align-items: center;

        .store-select-input {
          width: 200px;
        }
      }

      .scene-filter {
        display: flex;
        align-items: center;
      }

      .label {
        font-weight: 600;
        color: #606266;
        margin-right: 8px;
      }
    }

    :deep(.el-radio-button__inner) {
      background-color: #f5f7fa;
      border-color: #dcdfe6;
      color: #606266;

      &.is-active {
        background-color: #409eff;
        border-color: #409eff;
        color: #fff;
      }

      &.is-disabled {
        background-color: #f5f7fa;
        border-color: #e4e7ed;
        color: #c0c4cc;
        cursor: not-allowed;
      }
    }

    :deep(.active-radio) {
      .el-radio-button__inner {
        background-color: #409eff !important;
        border-color: #409eff !important;
        color: #fff !important;
        box-shadow: -1px 0 0 0 #409eff !important;
      }
    }
  }

  :deep(.el-table) {
    margin-top: 20px;
  }

  .dialog-content {
    .search-section {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      gap: 16px;

      .search-item {
        display: flex;
        align-items: center;

        .label {
          font-weight: 600;
          margin-right: 8px;
          color: #606266;
        }

        .search-input {
          width: 240px;
        }
      }
    }

    .combo-content {
      .content-item {
        .content-label {
          color: #606266;
          margin-bottom: 4px;
        }
      }
    }

    .pagination-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    padding: 0 20px;
  }
}
.detail-content {
  max-height: 420px;
  overflow-y: auto;
  padding-left: 50px;
  .label {
    line-height: 30px;
    font-weight: 600;
  }
  .content {
    line-height: 26px;
  }
}
::v-deep .el-form-item__label:before {
  content: '*';
  color: var(--el-color-danger);
  margin-right: 0.020833rem;
}
</style>
